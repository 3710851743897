"use client";
import { InputComponentType, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";

import React, { useState } from "react";
import { dropdownInputSchema } from "./DropdownInputSchema";
import { makeStyles } from "@fluentui/react-components";



export type DropdownProperties = {
    inputType: "dropdown"
    options: {
        [key: string]: {
            key: string;
            label: string;
        }
    }
    defaultValue?: string;
}
const dropdownStyles = makeStyles({
    select: {
       
        width: "100%",
        display: "block",
        height: "45px",
        padding: "6px 12px",
        fontSize: "14px",
        lineHeight: "1.42857143",
        color: "#555",
        backgroundColor: "fff",
        backgroundImage: "none",
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxShadow: "inset 0 1px 1px rgba(0, 0, 0, .075)",
        transition: "border-color ease-in-out .15s, box-shadow ease-in-out .15s"
    },
    buttonContainer :{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "20px",
        width: "100%",
        marginTop: "20px"
    }

})
export const DropdownInput: InputComponentType<DropdownProperties> = ({ questionModel, options }) => {
    const { answerQuestion } = useQuickForm();
    const styles = dropdownStyles();

    const handleChange = (value: string) => {
       
            answerQuestion(questionModel.logicalName, value); // Convert back to expected DropdownProperties
        
        
    };
 


    return (
        <div className={styles.buttonContainer}>
            <select onChange={e => handleChange(e.target.value)} className={styles.select} name={`${questionModel.logicalName}`} value={questionModel!.output ?? ''} id={questionModel.logicalName}>
                {(questionModel!.output ?? '') === '' && <option val-required="false">Vælg venligst</option>}
                {Object.entries(options).map(([key, o]) =>
                    <option val-required="false" value={o.key}>{o.label}</option>
                )}
                 

            </select>
            
        </div>
    );
};

DropdownInput.inputSchema = dropdownInputSchema;
registerInputComponent("dropdown", DropdownInput);